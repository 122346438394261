
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRefs,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRouter, useRoute } from "vue-router";
import * as Yup from "yup";
import { translate, translateKey } from "@/hooks/TranslateService";
import { IUser } from "@/store/modules/valored/UserModule";
import {
  ICompany,
  ICompanyYearlyParams,
} from "@/store/modules/valored/CompanyModule";
import { useStore } from "vuex";
import { toRaw } from 'vue';
import { Actions } from "@/store/enums/StoreEnums";
import { successMessage } from "@/core/services/AlertService";
import AddCompanySettingModal from "@/components/modals/forms/AddCompanySettingModal.vue";
import bus from "vue3-eventbus";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import router from "@/router";

export default defineComponent({
  name: "company-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
    AddCompanySettingModal,
  },
  setup() {
    const { t, locale } = useI18n();
    const submitButton = ref<HTMLElement | null>(null);
    const message_company_updated_succesfully = ref<string>(
      translate("FEEDBACK_COOKIE_COMPANY_EDIT_SUCCESSFUL")
    );

    const addCompanySettingModalRef =
      ref<InstanceType<typeof AddCompanySettingModal>>();
    
    bus.on("onGetCompanyYearlyParams", function (data) {
      if (data.latest == true) {
        addCompanySettingModalRef.value?.onShowModal();
      }
    });

    const store = useStore();
    const accountInfo = reactive({
      userProfile: computed(() => store.getters.currentUser as IUser),
      companyProfile: computed(
        () => store.getters.getCompanyProfile as ICompany
      ),
      companyYearlyParams: computed(
        () => store.getters.getCompanyYearlyParams as ICompanyYearlyParams
      ),
    });

    const companyName = ref(accountInfo.companyProfile.denom);
    const defaultCompanyName = ref(companyName.value);

    const azienda_yearly_parameters = reactive(
      computed(() => {
        if (accountInfo.companyYearlyParams) {
          return accountInfo.companyYearlyParams.azienda_yearly_parameters;
        }
        return null;
      })
    );

    const defaultCompanyType = ref(null);

    const resetFields = () => {
      store.dispatch(Actions.REQ_COMPANY_YEARLY_PARAMS).then((data) => {
        companyName.value = defaultCompanyName.value;
        bus.emit("onGetCompanyYearlyParams", data);
      });
    };

    const selectLists = reactive(
      computed(() => store.getters.getSelectOptionLists)
    );

    const profileDetailsValidator = Yup.object().shape({
      denom: Yup.string()
        .required(translate("COMPANY_NAME_REQUIRED"))
        .label("Company name "),
      settore: Yup.string()
        .required(translate("INDUSTRIAL_SECTOR_REQUIRED"))
        .label("Sector name"),
      fatturato: Yup.string()
        .required(translate("USERNAME_REQUIRED"))
        .label("Fatturato"),
      employees_range: Yup.string()
        .required(translate("EMPLOYEE_RANGE_REQUIRED"))
        .label(translate("AZIENDA_EMPLOYEES_RANGE_STRING"))
        .nullable(),
      is_associated: Yup.string()
        .required(translate("MEMBERSHIP_REQUIRED"))
        .label("Webside"),
      is_quoted: Yup.string()
        .required(translate("LISTING_REQUIRED"))
        .label("Country"),
      company_type: Yup.string()
        .required(translate("COMPANY_TYPE_REQUIRED"))
        .label("Company Type"),
    });

    const updateCompanyProfile = (values) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        Swal.fire({
          text: t("CHANGE_AZIENDA_SETTINGS_ALERT_MESSAGE"),
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: t("CHANGE_AZIENDA_SETTINGS_ALERT_CANCEL"),
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-dark-blue",
            cancelButton: "btn fw-bold btn-outline",
          },
        }).then(({ isConfirmed }) => {
          if (
            defaultCompanyType.value &&
            defaultCompanyType.value !== values.company_type
          ) {
            Swal.fire({
              text: t("CHANGE_COMPANY_TYPE_ALERT_MESSAGE"),
              icon: "warning",
              showCancelButton: true,
              cancelButtonText: t("CHANGE_AZIENDA_SETTINGS_ALERT_CANCEL"),
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-dark-blue",
                cancelButton: "btn fw-bold btn-outline",
              },
            }).then(({ isConfirmed }) => {
              if (isConfirmed) {

                store.dispatch(Actions.DELETE_SURVEY_PLUS)
                .then(() => {
                  store
                    .dispatch(Actions.UPDATE_COMPANY_PROFILE, values)
                    .then(() => {
                      store.dispatch(Actions.REQ_COMPANY);
                      store.dispatch(Actions.REQ_COMPANY_YEARLY_PARAMS);
                      successMessage(message_company_updated_succesfully.value);
                    })
                    .finally(() => {
                      submitButton.value?.removeAttribute("data-kt-indicator");
                    });
                })
              } else submitButton.value?.removeAttribute("data-kt-indicator");
            });
          } else {
            if (isConfirmed) {
            store
              .dispatch(Actions.UPDATE_COMPANY_PROFILE, values)
              .then(() => {
                store.dispatch(Actions.REQ_COMPANY);
                store.dispatch(Actions.REQ_COMPANY_YEARLY_PARAMS);
                successMessage(message_company_updated_succesfully.value);
              })
              .finally(() => {
                submitButton.value?.removeAttribute("data-kt-indicator");
              });
            } else submitButton.value?.removeAttribute("data-kt-indicator");
          }
        });
      }
    };

    onMounted(() => {
      store.dispatch(Actions.REQ_INITIAL_COMPARE_SELECT_OPTIONS);
      store.dispatch(Actions.REQ_COMPANY);

      if (!accountInfo.companyYearlyParams) {
        store.dispatch(Actions.REQ_COMPANY_YEARLY_PARAMS).then(() => {
          if (accountInfo.companyYearlyParams?.latest == true) {
            addCompanySettingModalRef.value?.onShowModal();
          }
        });
      }

      if (accountInfo.companyYearlyParams?.latest == true) {
        addCompanySettingModalRef.value?.onShowModal();
      }

      setTimeout(() => {
        store.dispatch(
          Actions.CHANGE_CURRENT_YEARLY_PERIOD,
          localStorage.getItem("to_year")
        );
      }, 1000);

      store.dispatch(Actions.REQ_COMPANY_YEARLY_PARAMS).then((data) => {
        defaultCompanyType.value = data.company_type;
        
        companyName.value = defaultCompanyName.value;
        bus.emit("onGetCompanyYearlyParams", data);
      });
    });

    return {
      submitButton,
      updateCompanyProfile,
      profileDetailsValidator,
      translate,
      ...toRefs(accountInfo),
      selectLists,
      translateKey,
      azienda_yearly_parameters,
      addCompanySettingModalRef,
      companyName,
      resetFields,
      locale
    };
  },
});
